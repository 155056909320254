@import "@/assets/styles/global/lib.scss";














































































































































































































































































































































































































































































































































.package-pricing {
  .highlighted-price {
    font-size: size(24);
    line-height: size(34);
    color: var(--rs-black-color);
    padding-top: size(20);
    font-weight: 700;
  }
  .text-button {
    &.rs-button {
      display: block;
      width: 100%;
      .link {
        display: block;
        font-size: size(12);
        line-height: size(15);
        font-weight: 700;
        color: var(--rs-white-color);
        border: size(1) solid var(--hero-text);
        padding: size(12) 0;
        background: var(--hero-text);
        border-radius: size(5);
        width: 100%;
        margin-top: size(16);
        text-align: center;
        cursor: pointer;
        &:hover {
          @include jump-up-animation;
        }
        &:after {
          border-color: var(--rs-white-color);
        }
      }
    }
  }

  .flag-text {
    padding: size(4) size(8);
    font-size: size(9);
    line-height: size(13);
    background:
    linear-gradient(90deg,#0000 33%,rgb(250 243 176) 50%,#0000 66%) #F6E337;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    font-weight: 600;
    color: var(--rs-black-color);
    border-radius: size(4);
    background-size:300% 100%;
    animation: shine 1.5s infinite;
    @keyframes shine {
      0% {
        background-position: right;
      }
    }
  }
  .unit-label {
    font-weight: 500;
    font-size: size(12);
    line-height: size(17);
    color: var(--rs-gray-color);
  }

  .package-price {
    .flag-text {
      margin: size(20) 0 size(8);
      display: inline-block;
    }
    .highlighted-price {
      padding-top: 0;
    }
    .rs-button {
      > a {
        margin-top: size(20);
      }
    }
  }
}

