@import "@/assets/styles/global/lib.scss";


























































































































































































































































































































































.banner-wrapper {
    background: #2D2F9D;
    border-radius: size(6);
    padding: size(30) size(20) 0;
    @media screen and (min-width: $breakpoint-md) {
        padding: size(30) size(20) size(50);
    }
    @media screen and (min-width: size(1024)) {
        padding-bottom: 0;
    }
    @media screen and (min-width: $breakpoint-lg) {
        padding: size(40) size(60);
    }
    .content-wrapper {
        display: inline-block;
        vertical-align: top;
        @media screen and (min-width: $breakpoint-md) {
            width: 50%;
        }
        @media screen and (min-width: $breakpoint-lg) {
            width: size(440);
        }
        .text-img {
            display: inline-block;
            width: size(98);
            padding-bottom: size(10);
            padding-top: size(20);
        }
        h6 {
            font-size: size(14);
            line-height: size(20);
            color: var(--rs-white-color);
            margin-bottom: size(10);
            margin-top: size(20);
            &.flag-text {
                clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
                background: #97E6AB;
                text-transform: uppercase;
                -webkit-clip-path: polygon(0% 0%, 100% 0%, 90% 50%, 100% 100%, 0% 100%);
                width: size(73);
                font-size: size(10);
                line-height: size(14);
                font-weight: 600;
                color: black;
                padding: size(3) size(16) size(3) size(9);
            }
        }
    }
    .image-wrapper {
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        padding-top: size(30);
        width: 100%;
        height: size(350);
        @media screen and (min-width: $breakpoint-md) {
            padding-left: size(30);
            width: 45%;
            padding-top: size(75);
            height: size(400);
        }
        @media screen and (min-width: size(1024)) {
            padding-top: 0;
        }
        @media screen and (min-width: $breakpoint-lg) {
            padding-left: size(50);
            width: auto;
        }
        .banner-artwork {
            display: inline-block;
            vertical-align: bottom;
            @media screen and (min-width: $breakpoint-md) {
                width: 100%;
            }
            @media screen and (min-width: $breakpoint-lg) {
                width: size(420);
            }
        }
    }
}
