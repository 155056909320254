@import "@/assets/styles/global/lib.scss";























































































































































img {
  max-width: 100%;
}
.rs-coverage {
  font-family: var(--rz-hero-font);
  .price-table {
    border: size(1) solid var(--rs-white-color);
    box-shadow: 0 size(4) size(20) rgba(0, 0, 0, 0.08);
    border-radius: size(6);
    margin-bottom: size(100);
    overflow-x: scroll;
    display: block;
    .table {
      &-cell, &-head {
        display: table-cell;
      }
      &-head {
        background: var(--rs-light-blue-color);
        font-weight: bold;
        padding: size(10);
        font-size: var(--rs-md-label-font-size);
        line-height: var(--rs-md-label-line-height);
        vertical-align: middle;
        color: var(--rs-black-color);
        &:nth-child(1) {
          background: var(--rs-black-color);
          color: var(--rs-white-color);
          padding-left: size(25);
        }
        &:nth-child(2) {
          padding-left: size(33);
        }
      }
      &-row {
        display: table-row;
        &:hover {
          border: size(1) solid #c4c4c4;
          box-shadow: 0 size(2) size(5) rgba(0, 0, 0, 0.05);
          background: var(--rs-white-color);
          .table-cell {
            border-right: size(1) solid var(--rs-white-color);
          }
        }
        &:nth-child(2) {
          .table-cell {
            padding-top: size(18);
          }
        }
        &:last-child {
          .table-cell {
            padding-bottom: size(18);
          }
        }
      }
      &-cell {
        padding: size(12) size(10);
        border-right: size(1) solid #EBE9E9;
        vertical-align: middle;
        font-size: var(--rs-md-label-font-size);
        line-height: var(--rs-md-label-line-height);
        color: var(--rs-cta-blue-color);
        min-width: size(100);
        &:last-child {
          border-right: size(1) solid transparent;
        }
        &:nth-child(2) {
          padding-left: size(33);
        }
        &.broadcast {
          color: var(--rs-pink-color);
        }
        &.available {
          color: var(--rs-gray-color);
        }
        &.one {
          min-width: size(220);
          max-width:  size(220);
          color: var(--rs-black-color);
          padding-left: size(25);
        }
      }
    }
    &.metrics {
      max-width: 90vw;
      margin: 0 auto;
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(675);
      }
      @media screen and (min-width: $breakpoint-lg) {
        max-width: initial;
        margin: initial;
      }
      .table {
        &-head {
          padding: size(20);
          &.one {
            padding-left: size(30);
          }
          &:last-child {
            padding-right: size(35);
          }
        }
        &-row {
          &:last-child {
            .table-cell {
              color: var(--rs-pink-color);
              &.one {
                color: var(--rs-black-color);
              }
            }
          }
        }
        &-cell {
          border-right: size(1) solid transparent;
          padding-left: size(20);
          &.one {
            min-width: size(150);
            max-width: size(150);
            font-weight: 500;
            padding-left: size(30);
          }
        }
      }

    }
    label {
      display: block;
      font-weight: normal;
    }
  }
  .tour-and-leagues {
    max-width: size(1360);
    margin: 0 auto;
    .rs-hero-title {
      max-width: initial;
      padding: 0 0 size(20) 0;
      text-align: center;
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
    }
    @media screen and (min-width: $breakpoint-md) {
      padding-top: size(50);
    }
  }
  h4 {
    font-size: var(--rs-title-level1-font-size);
    line-height: var(--rs-title-level1-line-height);
    color: var(--rs-black-color);
    font-weight: bold;
    text-align: center;
    max-width: size(800);
    margin: 0 auto;
    padding-bottom: size(80);
  }
  @media screen and (min-width: size(1400)) {
    .price-table {
      display: table;
    }
  }
  @media screen and (min-width: $breakpoint-lg) {
    .flex-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}
