@import "@/assets/styles/global/lib.scss";



























































































































































































































































































































































































































































































































































































































































































































































































































































































































#package-based {
  padding-top: size(60);
  .title {
    .p {
      @media screen and (min-width: $breakpoint-lg) {
        font-weight: 800;
        font-size: size(80);
        line-height: size(90);
        padding-bottom: size(20)
      }
      strong {
        color: #4F37C8;
      }
    }
  }
}
.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000;
  .popup {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: size(2);
    width: size(300);
    height: size(450);
    overflow: scroll;
    @media screen and (min-width: $breakpoint-md) {
      width: size(680);
      height: auto;
      overflow: auto;
    }
    .popup-header {
      svg {
        position: absolute;
        right: size(20);
        top: size(20);
        width: size(10);
        height: size(10);
        cursor: pointer;
      }
    }
  }
}
.content-section {
  max-width: size(550);
  padding: 0 0 size(30);
  @media screen and (min-width: $breakpoint-md) {
    padding-top: 0;
    padding-bottom: size(60);
  }
  .markdown {
    .p {
      font-weight: bold;
      font-size: var(--rs-title-level2-font-size);
      line-height: var(--rs-title-level2-line-height);
      padding-bottom: size(10);
      a {
        color: var(--hero-text);
      }
      strong {
        font-weight: bold;
      }
    }
  }
  .content {
    .p {
      font-size: size(16);
      line-height: size(24);
      font-weight: normal;
      padding-bottom: 0;
      @media screen and (min-width: $breakpoint-md) {
        font-size: size(18);
        line-height: size(28);
      }
      strong {
        font-weight: bold;
      }
    }
  }
}
